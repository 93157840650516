import React from "react"
import {
  Details,
  Summary,
} from "@fuegokit/gatsby-theme-fuegodocs/src/components/Details"
import { ImageWrapper } from "@fuegokit/gatsby-theme-fuegodocs/src/components/ImageWrapper"
import { Image } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Image"
import styled from "styled-components"
import { Text, Stack, Heading, Link, themeGet } from "@fuegokit/react"

import ArticleLayout from "../components/ArticleLayout"

const GetStartedDesignMonday = () => {
  return (
    <ArticleLayout title="Get started with Monday Vibe">
      <Heading as="h1">Get started with Monday Vibe</Heading>
      <Stack size={3}>
        <StyledHeading as="h2">How to use</StyledHeading>
        <Text>
          To use{" "}
          <Link
            href="https://www.figma.com/file/DyNkXJKVr6qNCWVt1WMrvX/%E2%AD%90%EF%B8%8F-Vibe-Monday-Components---Fuegokit?type=design&node-id=29380-33567&mode=design&t=GIdwYmKCbJqJsGSE-0"
            target="_blank"
          >
            Vibe Monday Components - Fuegokit
          </Link>{" "}
          in your project, locate the library and enable it from the Figma
          Assets tab.
        </Text>
        <Text>
          Use the Library finder under Assets in the left side navigation in
          Figma:
        </Text>

        <ImageWrapper>
          <Image
            src="https://github.com/mash312/fuegokit-imgs/assets/128430248/b1d8c04b-289e-446b-b4a9-4852fa44ae48"
            alt="Screenshot showing Libraries icon under Assets tab in Figma"
          />
        </ImageWrapper>
        <Text>
          Search for the Vibe Monday Components - Fuegokit library under Design
          Systems and enable it:
        </Text>
        <ImageWrapper>
          <Image
            src="https://github.com/mash312/fuegokit-imgs/assets/128430248/913c0070-5f30-4212-83d2-4fe49542c909"
            alt="Screenshot showing list of libraries in Libraries modal in Figma"
          />
        </ImageWrapper>
        <Text>You can now use the library! 🎉</Text>
      </Stack>
      <Stack size={3}>
        <StyledHeading as="h2">About</StyledHeading>
        <Details>
          <Summary>
            How is Vibe Monday Components - Fuegokit different from the Monday
            community version?
          </Summary>
          <Text>
            Our Monday library has a more complete set of components, variants,
            and patterns. It is in sync with the official{" "}
            <Link href="https://style.monday.com/?path=/story/welcome--page">
              Vibe | Design System
            </Link>
            , more accurate, and better maintained that the community Figma
            version.
          </Text>
        </Details>
      </Stack>
      <Stack size={3}>
        <StyledHeading as="h2">Support</StyledHeading>
        <Text>
          Need more info or want some help?{" "}
          <Link
            target="_blank"
            href="https://appfireworkspace.slack.com/archives/C024PR8ECCD"
          >
            Reach out in #design-systems Slack!
          </Link>
        </Text>
      </Stack>
    </ArticleLayout>
  )
}

const StyledHeading = styled(Heading)`
  font-size: ${themeGet("fontSizes.5")};
  margin-bottom: ${themeGet("space.2")};
`

export default GetStartedDesignMonday
